
import { AvButton } from "@/components";
import { AvSkeletonInput } from "@/components/av-skeleton";
// Services
import { useCity } from "@/services";
import { ICityLocal } from "@/store/admin-panel/city/cityState";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Interfaces
import {
  ICityUpdate,
  ICityUpdateResponse,
  IResponseCity,
} from "@core/services/interfaces/covarege-area/city/ICityService";
import { required } from "@validations";
import { Ref, ref } from "@vue/composition-api";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BSidebar,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

@Component({
  name: "CityHandler",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,

    // Avonale Components
    AvButton,
    AvSkeletonInput,

    // Form Validation
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,

    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isCityHandlerSidebarActive",
    event: "update:is-city-handler-sidebar-active",
  },
})
export default class CityHandler extends Vue {
  @Prop({ default: false }) isCityHandlerSidebarActive!: Ref<boolean>;
  @Prop({ default: [] }) statusOptions!: Array<{
    label: string;
    value: boolean;
  }>;
  @Prop({ required: true }) cityId!: string | null;
  @Prop({ default: () => {} }) clearCityIdData!: () => void;
  @Prop({ default: [] }) statesOptions!: Array<{
    label: string;
    value: number;
  }>;
  @Prop({ default: [] }) areasOptions!: Array<{ label: string; value: number }>;

  //Data
  blankCity: ICityLocal = {
    id: null,
    name: "",
    codeSerpro: null,
    codeIbge: "",
    capital: { label: "", value: null },
    state: { label: "", value: null },
    area: { label: "", value: null },
  };
  cityLocal: Ref<ICityLocal> = ref(this.blankCity);
  required = required;
  loading = ref(false);
  saving = false;

  resetCityLocal = () => {
    this.cityLocal.value.id = this.cityId;
  };

  clearForm = () => {
    if (typeof this.clearCityIdData == "function") this.clearCityIdData();

    this.cityLocal.value = this.blankCity;
  };

  formValidation = formValidation(this.resetCityLocal, this.clearForm);
  clearFormData = ref(this.formValidation.clearForm);

  // computed
  get isLoading(): boolean {
    return this.loading.value;
  }

  get isSidebarActive(): boolean {
    return this.isCityHandlerSidebarActive.value;
  }

  // Watchs
  @Watch("cityId", { immediate: true })
  setCityLocal() {
    if (typeof this.cityId == "number") {
      this.resetCityLocal();
    }
  }

  // ------------------------------------------------
  // isCitySidebarActive
  // * Limpar formulário se a barra lateral estiver fechada
  // * Consultar o município se tiver passando o ID
  // ------------------------------------------------
  @Watch("isSidebarActive", { immediate: true })
  async handleSidebar(val: boolean) {
    // ? Não redefina o evento até que a transição seja concluída
    if (!val) {
      setTimeout(() => {
        this.clearForm();
      }, 350);
    }

    if (this.isSidebarActive && this.cityId) {
      this.loading.value = true;
      this.cityLocal.value = await this.fetchCityById(this.cityId).finally(
        () => {
          this.loading.value = false;
        }
      );
    }
  }

  // Methods
  /**
   * Dispara ação de consultar município pelo ID
   * @param cityId
   */
  async fetchCityById(cityId: string): Promise<ICityLocal> {
    const cityBlank: ICityLocal = {
      id: null,
      name: "",
      codeSerpro: null,
      codeIbge: "",
      capital: { label: "", value: null },
      state: { label: "", value: null },
      area: { label: "", value: null },
    };

    return await useCity
      .requestGet(cityId)
      .then((response: IResponseCity) => {
        if (response.status == 200 || response.status == 201) {
          const cityResp = response.data.Data;

          return {
            id: cityResp.Id,
            name: cityResp.Nome,
            codeSerpro: cityResp.CodigoSerpro,
            codeIbge: "" + cityResp.CodigoIbge,
            capital: {
              label: cityResp.EhCapital ? "Sim" : "Não",
              value: cityResp.EhCapital,
            },
            state: { label: cityResp.Estado.Nome, value: cityResp.Estado.Id },
            area: {
              label: cityResp.AreaInfluenciaId
                ? cityResp.AreaInfluencia.Nome
                : "",
              value: cityResp.AreaInfluenciaId
                ? cityResp.AreaInfluencia.Id
                : null,
            },
          };
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Falha ao consultar município!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });

          return cityBlank;
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao consultar município!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return cityBlank;
      });
  }

  async fetchUpdateCity(city: ICityUpdate): Promise<ICityUpdateResponse> {
    return await useCity
      .requestUpdate(city)
      .then((response): ICityUpdateResponse => {
        if (response.data.Status == 200 || response.data.Status == 201) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Município atualizado com sucesso!",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$emit("refresh-data");
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Ocorreu um erro inesperado!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }

        return response;
      })
      .catch((response) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao atualizar município!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        return response;
      });
  }

  /**
   * Abrir ou fechar o sidebar de edição de um município
   */
  async onSubmit() {
    this.saving = true;

    await this.fetchUpdateCity({
      id: this.cityLocal.value.id || "",
      nome: this.cityLocal.value.name,
      codigoSerpro: this.cityLocal.value.codeSerpro || 0,
      codigoIbge: this.cityLocal.value.codeIbge,
      areaInfluenciaId: this.cityLocal.value.area.value
        ? this.cityLocal.value.area.value
        : undefined,
      estadoId: this.cityLocal.value.state.value
        ? this.cityLocal.value.state.value
        : undefined,
      ehCapital: this.cityLocal.value.capital.value
        ? this.cityLocal.value.capital.value
        : undefined,
    });

    this.saving = false;
    this.$emit("updateIsCityHandlerSidebarActive", false);
    this.$emit("refreshData");
  }

  toggleSidebarCityHandler = (val: boolean): void => {
    this.$emit("updateIsCityHandlerSidebarActive", val);
  };

  /**
   * Mapeando o valor do select status de um objecto <{label, value}> para apenas value boolean
   */
  reduceValueStatus = (val: { label: string; value: boolean }): boolean => {
    return val.value;
  };
}
